import classNames from 'classnames';
import { RefObject, useCallback, useEffect, useState, type JSX } from 'react';

import { InfoIconXs } from '@lichtblick/icons/svg/info/info-xs';
import { formatNumber } from '@lichtblick/localization';
import { Headline } from '@lichtblick/photon/atoms/headline/headline';
import { Text } from '@lichtblick/photon/atoms/text/text';
import { TextCTA } from '@lichtblick/photon/atoms/text-cta/text-cta';
import { Stepper } from '@lichtblick/photon/molecules/stepper/stepper';
import { Modal } from '@lichtblick/photon/organisms/modal/modal';
import { useTracker } from '@lichtblick/tracker';

import styles from './calculator-usage-input.module.scss';
import { gasUsagePerSquareMeter, squareMeterLimit } from './constants';

type GasUsageInputProps = {
  children: JSX.Element;
  onChange: (value: string, livingSpace: number) => void;
  usageRef: RefObject<HTMLInputElement | null>;
};

const calculateUsage = (livingSpace: number) => livingSpace * gasUsagePerSquareMeter;

export const GasUsageInput: React.FC<GasUsageInputProps> = ({ children, onChange, usageRef }) => {
  const { trackCustomEvent } = useTracker();
  const [livingSpace, setLivingSpace] = useState<number>(squareMeterLimit.default);
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);

  const handleStepperChange = useCallback(
    (value: number | undefined) => {
      if (value) {
        const cleanedValue = Math.max(squareMeterLimit.min, Math.min(value, squareMeterLimit.max));

        setLivingSpace(cleanedValue);

        if (usageRef.current) {
          usageRef.current.value = calculateUsage(cleanedValue).toString();
          onChange(usageRef.current.value, livingSpace);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setLivingSpace],
  );

  useEffect(() => {
    handleStepperChange(squareMeterLimit.default);
  }, [handleStepperChange]);

  return (
    <div>
      <Stepper
        label="Wohnfläche in m²"
        max={squareMeterLimit.max}
        min={squareMeterLimit.min}
        name="livingSpace"
        onChange={handleStepperChange}
        onClick={() => trackCustomEvent({ eventName: 'tariff_calculator_interaction' }, undefined, true)}
        step={squareMeterLimit.step}
        value={livingSpace}
      />
      <div className={styles['calculator-input-row']}>
        <Text size="xs">
          Dein Verbrauch{/* */}
          <input
            aria-invalid={usageRef.current?.checkValidity() === false}
            aria-label="Verbrauch in kWh/Jahr"
            className={classNames(
              styles['calculator-usage-input'],
              usageRef.current?.checkValidity() === false && styles['calculator-usage-input-error'],
            )}
            defaultValue={calculateUsage(squareMeterLimit.default)}
            name="gas"
            onChange={(event) => onChange(event.target.value, livingSpace)}
            onClick={() => trackCustomEvent({ eventName: 'tariff_calculator_interaction' }, undefined, true)}
            onInvalid={(e) => e.preventDefault()}
            ref={usageRef}
            required
          />
          {/* */}
          kWh/Jahr
        </Text>
        <TextCTA
          ariaLabel="Informationen zur Berechnung des Gasverbrauchs zeigen"
          as="button"
          icon={<InfoIconXs />}
          onClick={() => setIsInfoModalOpen(true)}
          type="button"
        />
      </div>

      <Modal
        footerContent={
          <TextCTA as="button" data-testid="button-modal-close" onClick={() => setIsInfoModalOpen(false)}>
            Ok, verstanden
          </TextCTA>
        }
        isOpen={isInfoModalOpen}
        onPointerDownOutside={() => setIsInfoModalOpen(false)}
        title="So berechnet LichtBlick den Gasverbrauch"
      >
        <div className={styles['modal-content']}>
          <Headline renderAs={'h2'} size="s">
            So berechnet LichtBlick den Gasverbrauch
          </Headline>
          <Text renderAs={'p'}>
            Natürlich wisst nur ihr genau, was ihr verbraucht und könnt es deswegen auch direkt eintragen. Aus langer
            Erfahrung als Gasanbieter kann LichtBlick aber auch ziemlich gut schätzen: Im Schnitt werden pro
            Quadratmeter {formatNumber(gasUsagePerSquareMeter)} kWh im Jahr verbraucht. Dabei ist natürlich
            miteingerechnet, dass im Sommer durchschnittlich deutlich weniger verbraucht wird als im Winter. Wenn
            zusätzlich mit Gas gekocht wird, kommen nochmal 1.000 kWh dazu. Diese müsstet ihr dann nur noch im
            Kalkulator hinzufügen, damit die Rechnung aufgeht. Doch weil auch wir bei der Berechnung nur mit Wasser
            kochen, könnt ihr den errechneten Wert jederzeit anpassen.
          </Text>
        </div>
      </Modal>
      {children}
    </div>
  );
};
