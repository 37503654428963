'use client';

import classNames from 'classnames';
import { FC, useActionState, useEffect, useState } from 'react';

import { InfoIconXs } from '@lichtblick/icons/svg/info/info-xs';
import { SearchIconXs } from '@lichtblick/icons/svg/search/search-xs';
import { BoxCTA } from '@lichtblick/photon/atoms/box-cta/box-cta';
import { Label } from '@lichtblick/photon/molecules/label/label';
import { useTracker } from '@lichtblick/tracker';
import { StatusMessage } from '@lichtblick/ui-components';

import { submitForm } from './actions';
import styles from './soft-funnel-entry.module.scss';
import { Textfield } from './Textfield';

import { SoftFunnelEntryType } from '../../../types';

const message = `Leider kein Installationspartner in deiner Nähe gefunden. Werde benachrichtigt, sobald das SolarPaket \
in deiner Region verfügbar ist.`;

export const SoftFunnelEntryCard: FC<Pick<SoftFunnelEntryType, 'theme' | 'title'>> = ({ theme, title }) => {
  const [state, dispatch, pending] = useActionState(submitForm, {
    partnerAvailable: undefined,
    zipCode: '',
  });
  const [currentZip, setCurrentZip] = useState('');

  const { trackCustomEvent } = useTracker();

  const isParkedLeadCase = state.partnerAvailable === false && currentZip === state.zipCode;

  useEffect(() => {
    if (state.partnerAvailable) {
      location.assign(`/zuhause-checkout?buildingPostalCode=${state.zipCode}&page=HouseTypePage`);
    }
  }, [state]);

  return (
    <div className={classNames(styles['stage-card'], styles[`theme-${theme}`])}>
      <form
        action={dispatch}
        className={styles['funnel-form']}
        onSubmit={() => {
          trackCustomEvent({ eventName: 'eaas_soft_funnel_cta' });
        }}
      >
        <Label bold htmlFor="zipCode" renderAs="label">
          {title || 'Wo soll Deine SolarAnlage installiert werden?'}
        </Label>
        <div className={styles['controls']}>
          <Textfield
            className={styles['text-field']}
            defaultValue={state.zipCode}
            forcedError={state.errorMessage}
            leftSlot={<SearchIconXs className={styles['styled-search-icon']} />}
            name="zipCode"
            onChange={({ target }) => setCurrentZip(target.value)}
            pattern="\d{5}"
            placeholder="Postleitzahl eingeben"
            required
          />
          <div className={styles['button-wrapper']}>
            {isParkedLeadCase ? (
              <BoxCTA
                as="a"
                href={`/zuhause-checkout?buildingPostalCode=${state.zipCode}&page=UnsupportedRegionPage`}
                variant="neutral"
                wide
              >
                Benachrichtigt mich
              </BoxCTA>
            ) : (
              <BoxCTA as="button" loading={pending} variant="primary" wide>
                SolarAnlage berechnen
              </BoxCTA>
            )}
          </div>
        </div>
        {isParkedLeadCase && <StatusMessage icon={<InfoIconXs />} message={message} status="action" type="outline" />}
      </form>
    </div>
  );
};

export const SoftFunnelEntry: FC<SoftFunnelEntryType> = ({
  paddingBottomDesktop,
  paddingBottomMobile,
  paddingTopDesktop,
  paddingTopMobile,
  theme,
  ...props
}) => {
  return (
    <section
      className={classNames(
        styles['root'],
        styles[`theme-${theme}`],
        styles['root-pbd-xl'],
        styles[`root-ptm-${paddingTopMobile || 'm'}`],
        styles[`root-pbm-${paddingBottomMobile || 'm'}`],
        styles[`root-ptd-${paddingTopDesktop || 'xl'}`],
        styles[`root-pbd-${paddingBottomDesktop || 'xl'}`],
        styles[`root-pbd-${paddingBottomDesktop || 'xl'}`],
      )}
    >
      <SoftFunnelEntryCard {...props} theme={theme} />
    </section>
  );
};
