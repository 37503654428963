import { FC } from 'react';
import styled from 'styled-components';

import { Colors, BorderRadius, Spacing, MediaMediumAndAbove } from '@lichtblick/theme';
import { Text, TextProps } from '@lichtblick/ui-components';

import { ColorKey, getColor } from '../../../../utils';

export const Rows = styled.div<{ $backgroundColor?: Colors }>`
  background: ${({ $backgroundColor }) => $backgroundColor || 'transparent'};
  border-radius: ${BorderRadius.S};
  display: flex;
  flex-direction: column;
  gap: ${Spacing['3Xs']};
  padding: ${Spacing.S};
  width: 100%;
`;

export const Row = styled.div<{ $isHeadline?: boolean }>`
  display: flex;
  gap: ${Spacing.Xxs};
  overflow: auto;
  position: relative;
`;

export const SecondRow = styled.div<{ $isHeadline?: boolean }>`
  display: flex;
  justify-content: space-between;
  overflow: auto;
  position: relative;
`;

export const Label = styled(Text)`
  z-index: 1;
`;

export const Divider = styled.span`
  align-self: stretch;
  background-image: linear-gradient(to right, ${Colors.Gray} 1px, ${Colors.FadedMoss} 1px);
  background-position: top;
  background-repeat: repeat-x;
  background-size: ${Spacing['3Xs']} 1px;
  content: '';
  flex: 1;
  height: 1px;
  margin-top: 0.875rem;
  width: 100%;
  z-index: 0;

  ${MediaMediumAndAbove} {
    margin-top: 1rem;
  }
`;

type PossibleColors = Extract<ColorKey, 'black' | 'red' | 'green'>;

export const Value = styled<FC<TextProps & { $color: PossibleColors }>>(Text)`
  color: ${({ $color = 'black' }) => getColor($color)};
  text-align: right;
  z-index: 1;
`;
