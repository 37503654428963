import styled, { css } from 'styled-components';

import { BorderRadius, Colors, MediaMediumAndAbove, Spacing, alpha } from '@lichtblick/theme';
import { AccentText, Headline } from '@lichtblick/ui-components';

import { AText } from '../AText';

export const Card = styled.div<{ $themeColor?: string }>`
  background-color: ${Colors.White};
  border-radius: ${BorderRadius.S};

  ${({ $themeColor }) =>
    $themeColor &&
    css`
      ${Badge} {
        background-color: ${$themeColor};
      }
      ${Body} {
        background-color: ${alpha($themeColor ?? Colors.Black, 0.1)};
      }
    `}

  padding-bottom: ${Spacing.Xs};
  text-align: center;
  width: 18rem;
`;

export const Badge = styled.div`
  border-radius: ${BorderRadius.S} ${BorderRadius.S} 0 0;
  line-height: 1.6875rem;
  padding: ${Spacing.Xs};
`;

export const StyledHeadline = styled(Headline)`
  margin-bottom: ${Spacing.Xxs};
`;

export const Header = styled.div`
  margin-bottom: ${Spacing.Xs};
  padding: ${Spacing.Xs};
`;

export const StyledTagline = styled(AText)`
  color: ${Colors.Gray};
`;

export const Body = styled.div`
  border-radius: ${BorderRadius.S};
  margin: 0 ${Spacing.Xs};
  padding: ${Spacing.S} ${Spacing.Xs} ${Spacing.Xs};
`;

export const TariffIcon = styled.div`
  display: flex;
  height: 4rem;
  margin: -3.5rem auto 0;
  width: 4rem;
`;

export const Container = styled.div`
  margin-bottom: ${Spacing.Xs};
`;

export const StyledText = styled(AText)`
  margin-bottom: ${Spacing.Xxs};
`;

export const StyledAccentText = styled(AccentText)<{ $color: string }>`
  color: ${({ $color }) => $color};
`;

export const InfoDialogLink = styled.div`
  margin-bottom: ${Spacing.Xs};
  margin-top: ${Spacing.Xs};
`;

export const Footer = styled.div`
  ul {
    border: 1px solid ${alpha(Colors.LightGray, 1)};
    border-radius: ${BorderRadius.S};
    padding: ${Spacing.Xs};
  }

  > * {
    margin: ${Spacing.Xxs} ${Spacing.Xs} 0;
  }
  ${MediaMediumAndAbove} {
    > * {
      margin: ${Spacing.Xs} ${Spacing.Xs} 0;
    }
  }
`;

export const FooterText = styled.div<{ $color?: string }>`
  color: ${({ $color }) => $color};
  margin-top: ${Spacing.Xs};
  text-align: center;
`;

export const StyledLinkText = styled(AText)`
  display: inline;
`;
