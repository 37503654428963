import styled from 'styled-components';

export const Column = styled.div<{ $isCentered?: boolean }>`
  display: flex;
  flex-direction: column;
  ${(props) => (props.$isCentered ? 'align-items: center;' : '')}
`;

export const HalfSizedColumn = styled(Column)`
  flex-basis: 50%;
`;
