import styled from 'styled-components';

import { Spacing } from '@lichtblick/theme';

import { StyledBulletItem } from '../ABulletItem/ABulletItem.styles';

export const Wrapper = styled.ul`
  ${StyledBulletItem} &:not(:last-of-type) {
    margin-bottom: ${Spacing.Xs};
  }
`;
