'use client';

import { FormEvent, useRef, useState } from 'react';

import { ElectricityIconM } from '@lichtblick/icons/svg/electricity/electricity-m';
import { GasIconM } from '@lichtblick/icons/svg/gas/gas-m';
import { BoxCTA } from '@lichtblick/photon/atoms/box-cta/box-cta';
import { Checkbox } from '@lichtblick/photon/atoms/checkbox/checkbox';
import { Label } from '@lichtblick/photon/molecules/label/label';
import { useTracker } from '@lichtblick/tracker';

import { CalculatorLayout } from './calculator-layout';
import styles from './calculator-layout.module.scss';
import { CityInput } from './CityInput';
import { PEOPLE_LIMIT, usageLimitElectricity, usageLimitGas } from './constants';
import { ElectrictyUsageInput } from './ElectricityUsageInput';
import { GasUsageInput } from './GasUsageInput';
import { BaseCalculatorProps } from './OAllInOneCalculator';
import { CalculatorTranslations } from './translations';
import { UsageModal } from './UsageModal';
import { useVpCodes } from './useVpCodes';

import { buildUrl } from '../../helpers/links';
import { useOptimizelyTrack } from '../../hooks/use-optimizely-track';
import { HouseholdCalculatorType } from '../../types';

type HouseholdCalculatorProps = BaseCalculatorProps & HouseholdCalculatorType;

type FormValues = {
  city?: string;
  electricityUsage?: string;
  gasUsage?: string;
  livingSpace?: number;
  peopleCount?: number;
  postcode?: string;
};

export const HouseholdCalculator: React.FC<HouseholdCalculatorProps> = ({
  _uid,
  checkoutUrl,
  chip,
  directSalesPartnerCode,
  headline,
  isActionTriggerDisabled,
  powerCloudCampaignId,
  productAType,
  productBSelected,
  productBType,
  useDirectSalesPartnerCodeSitewide,
}) => {
  const { computeVpCodes } = useVpCodes();
  const { trackCustomEvent } = useTracker();
  const trackOptimizelyEvent = useOptimizelyTrack();
  const gasUsageRef = useRef<HTMLInputElement>(null);
  const isElectricity = productAType === 'electricity';
  const electricityUsageRef = useRef<HTMLInputElement>(null);
  const [isGasUsageModalOpen, setIsGasUsageModalOpen] = useState(false);
  const [isElectricityUsageModalOpen, setIsElectricityUsageModalOpen] = useState(false);
  const [isProductBSelected, setIsProductBSelected] = useState(Boolean(productBSelected));

  const [values, setValues] = useState<FormValues>({
    peopleCount: PEOPLE_LIMIT.default,
  });

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (electricityUsageRef.current && Number(electricityUsageRef.current.value) > usageLimitElectricity.max) {
      setIsElectricityUsageModalOpen(true);

      return;
    }

    if (gasUsageRef.current && Number(gasUsageRef.current.value) > usageLimitGas.max) {
      setIsGasUsageModalOpen(true);

      return;
    }

    const vpCodes = computeVpCodes(directSalesPartnerCode, useDirectSalesPartnerCodeSitewide);

    if (isElectricity) {
      trackOptimizelyEvent(`calculated_electricity_product`);
    }

    trackCustomEvent({
      eventName: 'tariff_calculator_show_tariffs',
      customProps: {
        product: isElectricity ? 'ÖkoStrom' : 'ÖkoGas',
        zip_code: values?.postcode,
        ...(isElectricity && { people_counter: values?.peopleCount }),
        ...(!isElectricity && { living_space: `${values?.livingSpace} m²` }),
        consumption_calculator: isElectricity ? `${values?.electricityUsage} kWh/Jahr` : `${values?.gasUsage} kWh/Jahr`,
        bundle_product: isProductBSelected,
      },
    });

    const params = new URLSearchParams({
      ...(values?.city && { ort: values.city }),
      ...(values?.postcode && { plz: values.postcode }),
      ...((productAType === 'electricity' || isProductBSelected) &&
        values?.electricityUsage && { strom: values.electricityUsage }),
      ...((productAType === 'gas' || isProductBSelected) && values?.gasUsage && { gas: values.gasUsage }),
      ...(powerCloudCampaignId && { cam: powerCloudCampaignId }),
      ...(vpCodes?.vp && { vp: vpCodes.vp }),
      ...(vpCodes?.uvp && { uvp: vpCodes.uvp }),
    });

    location.assign(`${(checkoutUrl && buildUrl(checkoutUrl)) ?? '/checkout/'}?${params.toString()}`);
  };

  const electricityInput = (
    <ElectrictyUsageInput
      onChange={(value, peopleCount) =>
        setValues((prev) => ({ ...prev, electricityUsage: value, peopleCount: peopleCount }))
      }
      usageRef={electricityUsageRef}
    >
      <UsageModal
        isOpen={isElectricityUsageModalOpen}
        route="/gewerbestrom"
        setIsOpen={setIsElectricityUsageModalOpen}
      />
    </ElectrictyUsageInput>
  );

  const gasInput = (
    <GasUsageInput
      onChange={(value, livingSpace) => setValues((prev) => ({ ...prev, gasUsage: value, livingSpace: livingSpace }))}
      usageRef={gasUsageRef}
    >
      <UsageModal isOpen={isGasUsageModalOpen} route="/gewerbegas" setIsOpen={setIsGasUsageModalOpen} />
    </GasUsageInput>
  );

  return (
    <CalculatorLayout
      chip={chip}
      data-testid={`calculator-${isElectricity ? 'electricity' : 'gas'}`}
      headerColor={isElectricity ? 'LightGreen' : 'LightAqua'}
      headline={headline}
      icon={isElectricity ? <ElectricityIconM /> : <GasIconM />}
      id={_uid}
      isActionTriggerDisabled={isActionTriggerDisabled}
    >
      <form className={styles['calculator-content']} onSubmit={handleSubmit}>
        <div
          className={styles['calculator-input-row']}
          data-testid={isElectricity ? 'electricityCalculatorGroup' : 'gasCalculatorGroup'}
        >
          {isElectricity ? electricityInput : gasInput}
          <CityInput
            onChange={(city, postcode) => {
              setValues({ ...values, city: city, postcode: postcode });
            }}
          />
        </div>

        {productBType && (
          <>
            <Label className={styles['label']}>
              <Checkbox
                data-testid={`${productBType}_0`}
                defaultChecked={isProductBSelected}
                onChange={() => setIsProductBSelected(!isProductBSelected)}
              />
              {isElectricity ? 'Gas hinzufügen' : 'ÖkoStrom hinzufügen'}
            </Label>

            {isProductBSelected && (
              <div
                className={styles['calculator-input-row']}
                data-testid={isElectricity ? 'gasCalculatorGroup' : 'electricityCalculatorGroup'}
              >
                {isElectricity ? gasInput : electricityInput}
              </div>
            )}
          </>
        )}

        <BoxCTA as="button" data-testid="calc-submit" type="submit" wide>
          {CalculatorTranslations.submitLabel}
        </BoxCTA>
      </form>
    </CalculatorLayout>
  );
};
