import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

import { Icon } from '@lichtblick/icons';
import { BorderRadius, Colors, MediaSmallAndAbove, Spacing, placeholderElement } from '@lichtblick/theme';
import { Headline } from '@lichtblick/ui-components';

export const ProductCardTeaser = styled.div`
  background-color: ${Colors.FadedSmoke};
  border-radius: ${BorderRadius.S};
  padding: ${Spacing.S} ${Spacing.Xxs} ${Spacing.Xs};
  text-align: start;

  ${MediaSmallAndAbove} {
    padding: ${Spacing.S} ${Spacing.Xs} ${Spacing.Xs};
  }
`;

export const bounceIn = {
  '0%': {
    opacity: 0,
    transform: 'scale(0.3) translate3d(0, 0, 0)',
  },
  '50%': {
    opacity: 0.9,
    transform: 'scale(1.1)',
  },
  '80%': {
    opacity: 1,
    transform: 'scale(0.89)',
  },
  '100%': {
    opacity: 1,
    transform: 'scale(1) translate3d(0, 0, 0)',
  },
};

export const bounceOut = {
  '0%': {
    opacity: 1,
  },
  '100%': {
    opacity: 0.2,
  },
};

type TrademarkProps = {
  $isToggleEnabled: boolean;
};

export const Trademark = styled(motion.div)<TrademarkProps>`
  animation-fill-mode: forwards;
  display: flex;
  height: ${Spacing.S};
  margin: -2.25rem auto 0;
  width: ${Spacing.S};
  // fixes icon getting blurry
  will-change: opacity, transform;
`;

export const HeadlineWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${Spacing.Xs};
`;

export const StyledHeadline = styled(Headline)`
  margin-bottom: ${Spacing.Xxs};
`;

export const StyledIcon = styled(Icon)<{ $color?: string | null }>`
  ${({ $color }) =>
    $color &&
    css`
      color: ${$color};
    `}
`;

export const Description = styled.div``;

export const HeadlinePlaceholder = styled.div`
  height: 2rem;
  margin: auto;
  ${placeholderElement()}
  width: 75%;
`;
export const DescriptionPlaceholder = styled.div`
  height: 4rem;
  margin-bottom: 1rem;
  margin-top: 0.5rem;
  ${placeholderElement()}
`;
export const ButtonPlaceholder = styled.div`
  height: 2rem;
  ${placeholderElement()}
`;
