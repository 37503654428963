'use client';

import Script from 'next/script';
import { FC } from 'react';

export const OGeigFormScript: FC<{ formId?: string }> = ({ formId }) => (
  <Script
    async
    defer
    onReady={() => {
      (window as any).hbspt.forms.create({
        region: 'eu1',
        portalId: '143379264',
        formId,
        target: '#geig-form',
      });
    }}
    src="//js-eu1.hsforms.net/forms/embed/v2.js"
    type="text/javascript"
  />
);
