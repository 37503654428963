import styled from 'styled-components';

import { Spacing } from '@lichtblick/theme';

export const TechnicalDetailsItem = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${Spacing.S};

  &:last-child {
    margin-bottom: 0;
  }
`;
