'use client';

import { FC, PropsWithChildren } from 'react';

import { Icon, toIconName } from '@lichtblick/icons';
import { Colors } from '@lichtblick/theme';
import { Container, Text } from '@lichtblick/ui-components';

import { BenefitCardsRoot, Card, Headline, TwoCardRow } from './BenefitCards.styles';

import { ElementMappers, RichText } from '../../../helpers/RichText';
import { BenefitCardType, BenefitCardsType } from '../../../types';
import { getColor } from '../../../utils';
import { StyledBulletItem } from '../../ABulletItem/ABulletItem.styles';
import { AButton } from '../../AButton';
import { Wrapper as MBulletList } from '../../MBulletList/MBulletList.styles';
import { MTextIntro } from '../../MTextIntro';
import { Column } from '../shared';

type BenefitCardProps = PropsWithChildren<Omit<BenefitCardType, 'text' | 'component' | '_uid'>> & {
  backgroundColor: Colors;
};

const elementMappers: Partial<ElementMappers> = {
  ul: ({ children }) => <MBulletList>{children}</MBulletList>,
  li: ({ children }) => (
    <StyledBulletItem>
      <Text size="M">{children}</Text>
    </StyledBulletItem>
  ),
};

const BenefitCard = ({ backgroundColor, children, headline, icon, iconColor: shiftColor }: BenefitCardProps) => (
  <Card $backgroundColor={backgroundColor}>
    <Headline>
      <Icon name={toIconName(icon)} shiftColor={getColor(shiftColor)} /> {headline}
    </Headline>
    {children}
  </Card>
);

export const BenefitCards: FC<BenefitCardsType> = ({ button, cards, textIntro, theme }) => (
  <BenefitCardsRoot $backgroundColor={getColor(theme) || Colors.White}>
    {textIntro?.[0] && (
      <Container as="div">
        <MTextIntro {...textIntro[0]} centeredDesktop centeredMobile />
      </Container>
    )}
    <TwoCardRow>
      {cards.map(({ headline, icon, iconColor, text }) => (
        <BenefitCard
          backgroundColor={theme === 'white' ? Colors.FadedMoss : Colors.White}
          headline={headline}
          icon={icon}
          iconColor={iconColor}
          key={`BenefitCard-${headline}`}
        >
          <RichText elementMappers={elementMappers} richText={text} />
        </BenefitCard>
      ))}
    </TwoCardRow>
    <Container as="div">
      <Column $isCentered>{button?.[0] && <AButton {...button[0]} />}</Column>
    </Container>
  </BenefitCardsRoot>
);
