import styled from 'styled-components';

import { Colors, MediaSmallAndAbove, Spacing } from '@lichtblick/theme';
import { ContainerStyle } from '@lichtblick/ui-components';

export const StyledSection = styled.div<{ $backgroundColor: Colors }>`
  background-color: ${({ $backgroundColor }) => $backgroundColor};
`;
export const StyledContainer = styled.div`
  align-items: center;
  display: grid;
  gap: ${Spacing.L};
  grid-template-columns: 1fr;
  ${ContainerStyle}
  padding-bottom: ${Spacing.L};
  padding-top: ${Spacing.S};

  ${MediaSmallAndAbove} {
    grid-template-columns: 1fr 2fr;
    padding-bottom: ${Spacing.L};
    padding-top: ${Spacing.Xl};
  }
  & > div > figure > figcaption {
    margin-top: ${Spacing.Xs};
  }
`;
