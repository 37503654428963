import styled from 'styled-components';

import { Spacing } from '@lichtblick/theme';

import { ADataItem } from '../ADataItem';

export const StyledDataItem = styled(ADataItem)`
  text-align: left;

  &:not(:last-of-type) {
    margin-bottom: ${Spacing.Xs};
  }
`;
