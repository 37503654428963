'use client';

import classNames from 'classnames';
import { FC, PropsWithChildren, useEffect, useRef, useState } from 'react';

import { BackToTopButton } from './BackToTopButton';
import styles from './o-index.module.scss';
import { StickyNav } from './StickyNav';

import { OIndexType } from '../../types/storyblok';

type SectionWithScrollHandlingProps = PropsWithChildren & Pick<OIndexType, 'anchorId' | 'items' | 'theme'>;

export const SectionWithScrollHandling: FC<SectionWithScrollHandlingProps> = ({ anchorId, children, items, theme }) => {
  const sectionRef = useRef<HTMLElement>(null);
  const [isStickyNavVisible, setIsStickyNavVisible] = useState(false);
  const [isBackToTopButtonVisible, setIsBackToTopButtonVisible] = useState(false);
  const tickRef = useRef<boolean>(undefined);

  useEffect(() => {
    const divRef = sectionRef.current;

    if (!divRef) {
      return;
    }

    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsStickyNavVisible(entry.boundingClientRect.top < -entry.boundingClientRect.height);
      },
      {
        root: null,
        threshold: 0,
      },
    );

    observer.observe(divRef);

    return () => observer.unobserve(divRef);
  }, []);

  useEffect(() => {
    let lastScrollTop = window.scrollY || document.documentElement.scrollTop;

    const setScrollButtonVisibility = () => {
      if (tickRef.current) {
        return;
      }

      tickRef.current = true;

      window.requestAnimationFrame(() => {
        if (!isStickyNavVisible) {
          setIsBackToTopButtonVisible(false);
        } else {
          const scrollTopPosition = window.scrollY || document.documentElement.scrollTop;

          setIsBackToTopButtonVisible(scrollTopPosition <= lastScrollTop);
          lastScrollTop = Math.max(scrollTopPosition, 0);
        }

        tickRef.current = false;
      });
    };

    window.addEventListener('scroll', setScrollButtonVisibility);

    return () => window.removeEventListener('scroll', setScrollButtonVisibility);
  }, [isStickyNavVisible]);

  return (
    <>
      <section className={classNames(styles.section, styles[`section-bg-${theme}`])} id={anchorId} ref={sectionRef}>
        {children}
      </section>

      <StickyNav items={items} visible={isStickyNavVisible} />
      <BackToTopButton visible={isBackToTopButtonVisible} />
    </>
  );
};
