'use client';

import classNames from 'classnames';
import { FC } from 'react';

import { CheckIconXs } from '@lichtblick/icons/svg/check/check-xs';

import styles from './solar-stage.module.scss';

import { ElementMappers, RichText } from '../../../helpers/RichText';
import { SolarStageType } from '../../../types';
import { AImage } from '../../AImage/AImage';
import { StyledMList } from '../../MList/MList.styles';
import { MLogoItem } from '../../MLogoItem/MLogoItem';
import { MTextIntro } from '../../MTextIntro/MTextIntro';
import { SoftFunnelEntryCard } from '../SoftFunnelEntry';

const TextIntro: FC<Pick<SolarStageType, 'textIntro'>> = ({ textIntro }) => {
  if (!textIntro?.length) {
    return null;
  }

  const { button: _, ...rest } = textIntro[0];

  return (
    <MTextIntro
      {...rest}
      accentColor="white"
      className={styles['text-intro']}
      headlineProps={{
        size: 'l',
        renderAs: 'h1',
      }}
      textProps={{
        renderAs: 'div',
        align: 'left',
      }}
    />
  );
};

const elementMappers: Partial<ElementMappers> = {
  ul: StyledMList,
  li: ({ children }) => (
    <li className={styles['list-item']}>
      <CheckIconXs />
      {children}
    </li>
  ),
};

export const SolarStage: FC<SolarStageType> = ({ followingSectionTheme, logos, media, sellingPoints, textIntro }) => {
  const mediaItem = media?.[0]?.component === 'a-image' ? { ...media[0], imageDesktop: undefined } : undefined;

  return (
    <div className={styles['root']} data-testid="solar-stage">
      <div className={styles['stage-container']}>
        <div className={styles['content-container']}>
          <div className={styles['text-container']}>
            {logos?.length && (
              <div className={styles['logo-items']}>
                {logos.map((item) => (
                  <div className={styles['logo-item']} key={item._uid}>
                    <MLogoItem {...item} />
                  </div>
                ))}
              </div>
            )}
            <div className={styles['headline-container']}>
              {textIntro?.[0] && <TextIntro textIntro={textIntro} />}
              {sellingPoints && <RichText elementMappers={elementMappers} richText={sellingPoints} />}
            </div>
          </div>
        </div>
        {mediaItem && (
          <div className={styles['image-container']} data-image-container>
            {mediaItem ? <AImage {...mediaItem} isBackground loading="eager" maxWidth={1920} /> : null}
          </div>
        )}
      </div>

      <div
        className={classNames(styles['selling-points-section'], styles[`bg-${followingSectionTheme || 'faded-gray'}`])}
      >
        <div className={styles['selling-points-container']}>
          <SoftFunnelEntryCard theme="faded-gray" />
        </div>
      </div>
    </div>
  );
};
