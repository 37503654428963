import styled from 'styled-components';

import { MediaMediumAndAbove, Spacing } from '@lichtblick/theme';

export const Row = styled.div<{
  /** Spacing between content, default Spacing.L */
  $gap?: Spacing | 'None';
}>`
  display: flex;
  flex-direction: column;
  ${({ $gap }) => ($gap === 'None' ? '' : `gap: ${$gap ?? Spacing.L};`)}

  ${MediaMediumAndAbove} {
    flex-direction: row;
  }
`;
