'use client';

import NextImage from 'next/image';
import { FC } from 'react';

import { getFormattedFileSize } from './MDownloadItem.helpers';
import {
  DownloadItem,
  Image,
  ImageContainer,
  MetaCollection,
  MetaItem,
  StyledHeadline,
  StyledIcon,
  StyledText,
  TextWrapper,
} from './MDownloadItem.styles';

import { MDownloadItemType } from '../../types/storyblok';
import { AImage } from '../AImage';

export type DownloadItemProps = MDownloadItemType & {
  className?: string;
};

export const MDownloadItem: FC<DownloadItemProps> = ({
  asset,
  className,
  downloadAsset,
  fileSize,
  frontify,
  headline,
  metaInfo,
  previewAlignment,
}) => {
  const file = asset?.[0];
  let imageUrl = file?.generic_url?.replace('?width={width}', '');
  let extension = file?.ext ?? '';
  let downloadUrl = file?.download_url;
  let isImageFile = new RegExp(/jpg|tif|png/).test(extension);
  let metaInfos = isImageFile
    ? [metaInfo || file?.description]
    : [extension.toUpperCase(), getFormattedFileSize(Number(file?.filesize))];

  if (downloadAsset?.filename) {
    downloadUrl = downloadAsset.filename;
    extension = new RegExp(/\.(\w+)$/).exec(downloadAsset.filename)?.[1] ?? '';
    isImageFile = new RegExp(/jpg|tif|png/).test(extension);
    metaInfos = isImageFile ? [metaInfo || extension.toUpperCase()] : [extension.toUpperCase()];
    imageUrl = downloadUrl;

    if (fileSize) {
      metaInfos.push(`${String(Math.round(Number(fileSize) / 1000) / 100).replace('.', ',')} MB`);
    }
  }

  if (frontify?.[0]?.downloadUrl) {
    downloadUrl = frontify[0].downloadUrl;
    extension = frontify[0].extension;
    isImageFile = frontify[0].type === 'Image';
    metaInfos = [extension.toUpperCase()];
    imageUrl = frontify[0].previewUrl;

    if (frontify[0].size) {
      metaInfos.push(`${String(Math.round(Number(frontify[0].size) / 10000) / 100).replace('.', ',')} MB`);
    }
  }

  return (
    <DownloadItem
      className={className}
      data-testid="download-item"
      download
      href={downloadUrl}
      target={downloadAsset?.filename ? '_blank' : undefined}
    >
      {isImageFile && typeof imageUrl === 'string' && (
        <ImageContainer>
          <Image previewAlignment={previewAlignment}>
            {frontify?.[0]?.previewUrl ? (
              <NextImage alt={''} height={frontify[0].height} src={imageUrl} width={frontify[0].width} />
            ) : (
              <AImage
                altText={(downloadAsset?.alt || file?.description) ?? ''}
                imageMobile={{ filename: imageUrl, name: file?.title ?? '', id: 0 }}
              />
            )}
          </Image>
        </ImageContainer>
      )}
      <TextWrapper>
        <StyledText>
          <StyledHeadline renderAs="p" size="S">
            <span>{headline ?? file?.title}</span>
          </StyledHeadline>
          <MetaCollection>
            {metaInfos.map((meta) => (
              <MetaItem key={meta} size="s">
                {meta}
              </MetaItem>
            ))}
          </MetaCollection>
        </StyledText>
        <StyledIcon name="DownloadIconS" />
      </TextWrapper>
    </DownloadItem>
  );
};
