'use client';

import { FormEvent, useState } from 'react';

import { HeatingEnergyIconM } from '@lichtblick/icons/svg/heating-energy/heating-energy-m';
import { BoxCTA } from '@lichtblick/photon/atoms/box-cta/box-cta';
import { RadioGroup } from '@lichtblick/photon/molecules/radio/radio-group';
import { RadioInput } from '@lichtblick/photon/molecules/radio/radio-input';
import { useTracker } from '@lichtblick/tracker';

import { CalculatorLayout } from './calculator-layout';
import styles from './calculator-layout.module.scss';
import { CityInput } from './CityInput';
import { type BaseCalculatorProps } from './OAllInOneCalculator';
import { CalculatorTranslations } from './translations';
import { useVpCodes } from './useVpCodes';

import { buildUrl } from '../../helpers/links';
import { HeatingCalculatorType } from '../../types';

type FormValues = {
  city?: string;
  postcode?: string;
  sourceOfHeat?: string;
};

type HeatingCalculatorProps = BaseCalculatorProps & HeatingCalculatorType;

export const HeatingCalculator: React.FC<HeatingCalculatorProps> = ({
  _uid,
  checkoutUrl,
  chip,
  directSalesPartnerCode,
  headline,
  isActionTriggerDisabled,
  powerCloudCampaignId,
  sourceOfHeat,
  useDirectSalesPartnerCodeSitewide,
}) => {
  const { trackCustomEvent } = useTracker();

  const { computeVpCodes } = useVpCodes();
  const [values, setValues] = useState<FormValues>({
    sourceOfHeat: sourceOfHeat,
  });

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    trackCustomEvent({
      eventName: 'tariff_calculator_show_tariffs',
      customProps: {
        product: 'WärmeStrom',
        product_variant: values.sourceOfHeat === 'heat_pump' ? 'Wärmepumpe' : 'Nachtspeicherheizung',
        zip_code: values.postcode,
      },
    });

    const vpCodes = computeVpCodes(directSalesPartnerCode, useDirectSalesPartnerCodeSitewide);

    const params = new URLSearchParams({
      ...(values?.city && { ort: values.city }),
      ...(values?.postcode && { plz: values.postcode }),
      ...(values?.sourceOfHeat && { heizquelle: values.sourceOfHeat }),
      ...(powerCloudCampaignId && { cam: powerCloudCampaignId }),
      ...(vpCodes?.vp && { vp: vpCodes.vp }),
      ...(vpCodes?.uvp && { uvp: vpCodes.uvp }),
    });

    location.assign(`${buildUrl(checkoutUrl) ?? '/waermestrom-checkout/'}?${params.toString()}`);
  };

  return (
    <CalculatorLayout
      chip={chip}
      data-testid={`calculator-heat`}
      headerColor="LightBerry"
      headline={headline}
      icon={<HeatingEnergyIconM />}
      id={_uid}
      isActionTriggerDisabled={isActionTriggerDisabled}
    >
      <form className={styles['calculator-content']} method="get" onSubmit={handleSubmit}>
        <RadioGroup label="Womit heizt du?">
          <div className={styles['calculator-input-row']}>
            <RadioInput
              className={styles['radio-input']}
              defaultChecked={values.sourceOfHeat === 'heat_pump'}
              label="Wärmepumpe"
              name="sourceOfHeat"
              onChange={(event) => setValues((prev) => ({ ...prev, sourceOfHeat: event.target.value }))}
              onClick={() => trackCustomEvent({ eventName: 'tariff_calculator_interaction' }, undefined, true)}
              required
              value="heat_pump"
            />
            <RadioInput
              className={styles['radio-input']}
              defaultChecked={values.sourceOfHeat === 'storage_heater'}
              label="Nachtspeicherheizung"
              name="sourceOfHeat"
              onChange={(event) => setValues((prev) => ({ ...prev, sourceOfHeat: event.target.value }))}
              onClick={() => trackCustomEvent({ eventName: 'tariff_calculator_interaction' }, undefined, true)}
              required
              value="storage_heater"
            />
          </div>
        </RadioGroup>
        <CityInput onChange={(city, postcode) => setValues((prev) => ({ ...prev, city: city, postcode: postcode }))} />
        <BoxCTA as="button" data-testid="calc-submit" type="submit" wide>
          {CalculatorTranslations.heatSubmitLabel}
        </BoxCTA>
      </form>
    </CalculatorLayout>
  );
};
