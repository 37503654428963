import styled from 'styled-components';

import { Spacing } from '@lichtblick/theme';

export const StyledBulletItem = styled.li`
  display: flex;
  &::before {
    content: '•';
    margin-right: ${Spacing.Xxs};
  }
`;
