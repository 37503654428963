import classNames from 'classnames';
import { forwardRef, ReactNode } from 'react';

import styles from './checkbox.module.scss';

import { Checkbox, CheckboxElementProps } from '../../atoms/checkbox/checkbox';
import { FieldFooter } from '../field-footer/field-footer';
import { Label } from '../label/label';

type BaseProps = Omit<CheckboxElementProps, 'value' | 'disabled'> & {
  'data-testid'?: string;
  hasBoldLabel?: boolean;
  hasError?: boolean;
  isDisabled?: boolean;
  statusLabel?: string;
  value?: string | number | readonly string[];
};

export type CheckboxInputProps =
  | (BaseProps & { label: string; labelSlot?: never })
  | (BaseProps & { label?: never; labelSlot: ReactNode });

export const CheckboxInput = forwardRef<HTMLInputElement, CheckboxInputProps>(
  (
    {
      'data-testid': dataTestId,
      hasBoldLabel,
      hasError,
      isDisabled,
      label,
      labelSlot,
      name,
      statusLabel,
      value,
      ...props
    },
    ref,
  ) => {
    const id = value ? `${name}_${value}` : name;

    return (
      <div className={styles.wrapper}>
        <Checkbox
          data-testid={dataTestId}
          disabled={isDisabled}
          id={id}
          name={name}
          value={value}
          {...props}
          className={classNames(styles.checkbox, props.className)}
          ref={ref}
          state={hasError ? 'error' : isDisabled ? 'disabled' : 'default'}
        />
        <Label
          bold={hasBoldLabel}
          className={classNames(styles.label, isDisabled && styles['label-disabled'])}
          htmlFor={id}
        >
          {label ?? labelSlot}
          {statusLabel && <FieldFooter label={statusLabel} state={hasError ? 'error' : 'default'} />}
        </Label>
      </div>
    );
  },
);
