'use client';
import { FC, useMemo, useState } from 'react';

import { SelectInput } from '@lichtblick/ui-components';

import styles from './o-teaser-grid-articles.module.scss';
import { TeaserGridArticlesProps, UnmappedMTeaserItemProps } from './OTeaserGridArticles';

import { TArticleType } from '../../types';
import { OTeaserGrid } from '../OTeaserGrid/OTeaserGrid';

type Category = NonNullable<TArticleType['categories']>[number];

export const TeaserGridArticlesWithSelect: FC<
  TeaserGridArticlesProps & {
    articles: UnmappedMTeaserItemProps[];
  }
> = ({ _uid, accent, anchorId, articles, textIntro, theme }) => {
  const [category, setCategory] = useState<Category | ''>('');

  const options = useMemo(() => {
    const usedCategories = articles
      .flatMap((article) => article.categories)
      .filter((value) => typeof value === 'string') as string[];

    return usedCategories
      .filter((value, index, array) => {
        return array.indexOf(value) === index;
      })
      .sort()
      .map((category) => ({
        value: category,
        label: category,
      }));
  }, [articles]);

  const filteredArticles = useMemo(
    () => articles.filter((article) => category === '' || article.categories?.includes(category)),
    [articles, category],
  );

  return (
    <>
      <div className={styles['select-input-wrapper']}>
        <SelectInput
          allowEmptySelection
          className={styles['select-input']}
          name="filter"
          onChange={(e) => setCategory(e.target.value as Category)}
          options={options}
          placeholder="Kategorie"
          value={category}
        />
      </div>
      <OTeaserGrid
        _uid={_uid}
        accent={accent}
        anchorId={anchorId}
        columns={articles.length > 2 ? '3' : '2'}
        component="o-teaser-grid"
        items={filteredArticles}
        textIntro={textIntro}
        theme={theme}
      />
    </>
  );
};
