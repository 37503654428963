'use client';

import classNames from 'classnames';
import { FC, useState } from 'react';

import { Headline } from '@lichtblick/photon/atoms/headline/headline';
import { Text } from '@lichtblick/photon/atoms/text/text';

import styles from './m-e-auto-tariff-card.module.scss';

import { MEautoTariffCardType } from '../../types/storyblok';
import { AButton } from '../AButton';
import { AImage } from '../AImage';
import { AListItem } from '../AListItem';

type MEautoTariffCardProps = MEautoTariffCardType & {
  className?: string;
};

export const MEAutoTariffCard: FC<MEautoTariffCardProps> = ({
  className,
  customerSwitchLabel,
  existingCustomerActionButton,
  existingCustomerHeadline,
  existingCustomerInfoDialogButton,
  existingCustomerList,
  existingCustomerSubheadline,
  icon,
  newCustomerActionButton,
  newCustomerHeadline,
  newCustomerInfoDialogButton,
  newCustomerList,
  newCustomerSubheadline,
}) => {
  const [isExistingCustomer, setIsExistingCustomer] = useState(false);

  const headline = isExistingCustomer ? existingCustomerHeadline : newCustomerHeadline;
  const subheadline = isExistingCustomer ? existingCustomerSubheadline : newCustomerSubheadline;
  const dialogButton = isExistingCustomer ? existingCustomerInfoDialogButton?.[0] : newCustomerInfoDialogButton?.[0];
  const actionButton = isExistingCustomer ? existingCustomerActionButton?.[0] : newCustomerActionButton?.[0];
  const list = isExistingCustomer ? existingCustomerList?.[0]?.items : newCustomerList?.[0]?.items;

  return (
    <div className={classNames(styles.card, className)}>
      <div className={styles.header}>
        {icon?.length === 1 && <AImage className={styles.icon} {...icon[0]} />}

        {headline && (
          <Headline renderAs="h3" size="s">
            {headline}
          </Headline>
        )}

        {subheadline && (
          <Headline className={styles.subheadline} renderAs="h4" size="s">
            {subheadline}
          </Headline>
        )}
      </div>

      <div className={styles['switch-wrapper']}>
        <Text bold renderAs="h5" size="xs">
          {customerSwitchLabel}
        </Text>

        <div className={classNames(styles['switch-input'], !isExistingCustomer && styles['switch-input-right-active'])}>
          <button
            className={classNames(styles['switch-button'], isExistingCustomer && styles['switch-button-active'])}
            onClick={() => setIsExistingCustomer(true)}
          >
            Ja
          </button>

          <button
            className={classNames(styles['switch-button'], !isExistingCustomer && styles['switch-button-active'])}
            onClick={() => setIsExistingCustomer(false)}
          >
            Nein
          </button>
        </div>
      </div>

      {Boolean(list?.length) && (
        <ul className={styles.list}>{list?.map((props) => <AListItem key={props._uid} {...props}></AListItem>)}</ul>
      )}

      {(dialogButton || actionButton) && (
        <div className={styles.footer}>
          {dialogButton && <AButton hasNoIcon {...dialogButton} isSlim variant="link" />}
          {actionButton && <AButton {...actionButton}>{actionButton.text}</AButton>}
        </div>
      )}
    </div>
  );
};
