import styled from 'styled-components';

import { Icon } from '@lichtblick/icons';
import { Colors, Spacing } from '@lichtblick/theme';
import { Text, TextSizeClass } from '@lichtblick/ui-components';

import { ColorKey, colorMap } from '../../utils';

export const StyledItem = styled.li`
  align-items: flex-start;
  display: flex;
  flex-wrap: nowrap;
  list-style: none;
  width: 100%;
  & > :last-child {
    flex-grow: 1;
  }
`;

export const StyledPairedItem = styled.li`
  display: flex;
  align-items: center;
  gap: 0.5em;
  margin-bottom: 0.5em;
`;

export const StyledPairedItemText = styled(Text)`
  font-weight: normal;
`;

export const StyledPairedItemDottedLine = styled.span`
  flex: 1;
  margin-top: 0.7em;
  height: 1px;
  background-image: linear-gradient(to right, ${Colors.Gray} 1px, ${Colors.White} 1px);
  background-position: 50% 0;
  background-size: 4px 1px;
  background-repeat: repeat-x;
`;

export const StyledIcon = styled(Icon)<{ $color: ColorKey; $textSize: TextSizeClass }>`
  color: ${({ $color }) => colorMap[$color]};
  flex-shrink: 0;
  margin-right: ${Spacing.Xs};
  margin-top: ${({ $textSize }) => {
    if ($textSize === 'S') {
      return '0.125rem';
    } else {
      return '0.3125rem';
    }
  }};
`;
