import styled from 'styled-components';

import { Spacing } from '@lichtblick/theme';

export const StyledMList = styled.ul`
  width: 100%;
  & > :not(:last-of-type) {
    margin-bottom: ${Spacing.Xs};
  }
`;
