import styled, { css } from 'styled-components';

import {
  BorderRadius,
  Colors,
  MediaSmallAndAbove,
  MediaBelowSmall,
  Spacing,
  placeholderElement,
} from '@lichtblick/theme';

import { AText } from '../AText';

export const Card = styled.div`
  background-color: ${Colors.White};
  border-radius: ${BorderRadius.M} ${BorderRadius.M} ${BorderRadius.S} ${BorderRadius.S};
  margin: ${Spacing.Xs} ${Spacing.Xs} 0;
  padding: ${Spacing.Xs};
  position: relative;
  text-align: center;
  width: 13.5rem;

  ${MediaBelowSmall} {
    flex-shrink: 0;
    order: 1;
    scroll-snap-align: center;
  }

  ${MediaSmallAndAbove} {
    width: 17.5rem;
  }
`;

export const ChipWrapper = styled.div`
  margin-left: -1rem;
  position: absolute;
  top: -0.875rem;
  width: 100%;
`;

export const Curtain = styled.div<{ $theme: string }>`
  background-color: ${({ $theme }) => $theme};
  border-radius: ${BorderRadius.S} ${BorderRadius.S} 0 0;
  color: ${Colors.White};
  margin: -1rem -1rem 0;
  padding: ${Spacing.M} ${Spacing.Xs} calc(3 / 4 * 50% + 1.5rem);
`;

export const HeadlinePlaceholder = styled.div`
  height: 4rem;
  ${placeholderElement()}
  opacity: 0.5;
  width: 100%;
`;

export const PricePlaceholder = styled.div`
  height: 2rem;
  margin: ${Spacing.Xxs} ${Spacing.Xl};
  ${placeholderElement()}
`;

export const SublinePlaceholder = styled.div`
  height: 1.5rem;
  margin: 0 ${Spacing.M};
  ${placeholderElement()}
`;
export const ButtonPlaceholder = styled.div`
  height: 2rem;
  margin: 0 ${Spacing.L};
  ${placeholderElement()}
`;

export const Image = styled.div`
  height: 0;
  margin-top: calc(-3 / 4 * 50%);
  padding-top: calc(3 / 4 * 100%);
  position: relative;
  width: 100%;
`;

export const PlaceholderImage = styled.div`
  height: 90%;
  left: 0;
  ${placeholderElement()}
  opacity: 0.5;
  position: absolute;
  top: 0;
  width: 100%;
`;

export const ImageContent = styled.div`
  height: 100%;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
`;

export const PriceWrapper = styled.div<{ $isHighlighted: boolean; $isLoading?: boolean }>`
  margin-top: ${Spacing.Xs};
  ${({ $isLoading }) => $isLoading && placeholderElement()}

  ${MediaSmallAndAbove} {
    margin-top: ${Spacing.S};

    ${({ $isHighlighted }) =>
      $isHighlighted &&
      css`
        margin-top: ${Spacing.L};
      `}
  }
`;

export const StyledPriceSubline = styled(AText)`
  color: ${Colors.DarkGray};
  height: 1.25rem;
`;

export const List = styled.div<{ $isHighlighted: boolean }>`
  margin-top: ${Spacing.S};
  text-align: start;

  ${MediaSmallAndAbove} {
    ${({ $isHighlighted }) =>
      $isHighlighted &&
      css`
        margin-top: ${Spacing.M};
      `}
  }
`;

export const Teaser = styled.div`
  margin: ${Spacing.M} -${Spacing.Xxs} 0;
  text-align: start;

  ${MediaSmallAndAbove} {
    margin: ${Spacing.M} 0 0;
  }
`;

export const Button = styled.div<{ $isHighlighted: boolean }>`
  margin-bottom: ${Spacing.Xs};
  margin-top: ${Spacing.S};

  ${MediaSmallAndAbove} {
    ${({ $isHighlighted }) => $isHighlighted && ` margin-top: ${Spacing.L}};`}
  }

  ${MediaBelowSmall} {
    font-size: ${Spacing.Xs};
  }
`;
