import styled, { css } from 'styled-components';

import { MediaMediumAndAbove, Spacing } from '@lichtblick/theme';
import { Headline, StyledTextCTALink } from '@lichtblick/ui-components';

import { getColumnWidth } from '../shared/GridContainer/GridContainer';

export const ColumnItemContainer = styled.div<{ $alignment?: string; $width?: string }>`
  flex: 0 0 100%;

  ${({ $alignment }) =>
    $alignment === 'center' &&
    css`
      text-align: center;
    `}

  ${MediaMediumAndAbove} {
    ${({ $width }) =>
      $width === 'wide' &&
      css`
        flex-basis: calc(${getColumnWidth(5)} - ${2 / 3}rem);
      `}

    ${({ $width }) =>
      $width === 'narrow' &&
      css`
        flex-basis: calc(${getColumnWidth(4)} - ${2 / 3}rem);
      `}
  }

  ${StyledTextCTALink} {
    margin-top: ${Spacing.Xxs};
  }
`;

export const StyledHeadline = styled(Headline)`
  margin: ${Spacing.Xs} 0 ${Spacing.Xxs};

  &:first-child {
    margin-top: 0;
  }
`;
