import { FC } from 'react';

import { Colors } from '@lichtblick/theme';
import { Headline, Text } from '@lichtblick/ui-components';

import { MProductItemListRoot, ProductItemList, ProductListItem, ImageWrapper } from './MProductItemList.styles';
import { ProductInformation } from './shared';

import { ElementMappers, RichText } from '../../../../helpers/RichText';
import { SolarProductBundleType, ProductListItemType } from '../../../../types';
import { AButton } from '../../../AButton';
import { AImage } from '../../../AImage';
import { StyledItem, StyledIcon } from '../../../AListItem/AListItem.styles';
import { StyledMList } from '../../../MList';

export type MProductItemListProps = {
  backgroundColor: Colors;
  cta: SolarProductBundleType['cta'];
  href: string;
  items: [ProductListItemType?, ProductListItemType?, ProductListItemType?, ProductListItemType?];
};

const elementMappers: Partial<ElementMappers> = {
  ul: ({ children }) => <StyledMList>{children}</StyledMList>,
  li: ({ children }) => (
    <StyledItem>
      <StyledIcon $color="light-moss" $textSize="S" name={'CheckIconXs'} />
      {children}
    </StyledItem>
  ),
};

export const MProductItemList: FC<MProductItemListProps> = ({ backgroundColor, cta, items }) => {
  const itemIsDefined = (item?: ProductListItemType): item is ProductListItemType => Boolean(item);
  const sanitizedItems = items.filter(itemIsDefined);
  const numItems = sanitizedItems.length < 2 ? 2 : sanitizedItems.length;

  return (
    <MProductItemListRoot $backgroundColor={backgroundColor}>
      <ProductItemList numItems={numItems}>
        {sanitizedItems.map(({ image, sellingPoints, subtitle, textCTA, title }) => (
          <ProductListItem key={title}>
            {image?.[0] && (
              <ImageWrapper>
                <AImage {...image[0]} />
              </ImageWrapper>
            )}
            <ProductInformation>
              <Text size="Xs">{subtitle}</Text>
              <Headline renderAs="h3" size="S">
                {title}
              </Headline>
              {sellingPoints && <RichText elementMappers={elementMappers} richText={sellingPoints} />}
            </ProductInformation>
            {textCTA?.[0] && <AButton {...textCTA[0]} variant="link" />}
          </ProductListItem>
        ))}
      </ProductItemList>
      <div>{cta?.length === 1 && <AButton {...cta[0]} />}</div>
    </MProductItemListRoot>
  );
};
