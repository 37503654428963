import styled, { css } from 'styled-components';

import { MediaMediumAndAbove } from '@lichtblick/theme';

const FIXED_HEIGHT_LAYOUT = 'fixed-height';

export const Root = styled.div<{
  $desktopHeight: number;
  $desktopWidth: number;
  $layout: string;
  $mobileHeight: number;
  $mobileWidth: number;
}>`
  --aspect-ratio: ${({ $mobileHeight, $mobileWidth }) => $mobileWidth / $mobileHeight};
  --height: ${({ $mobileHeight }) => $mobileHeight}px;

  ${MediaMediumAndAbove} {
    --aspect-ratio: ${({ $desktopHeight, $desktopWidth }) => $desktopWidth / $desktopHeight};
    --height: ${({ $desktopHeight }) => $desktopHeight}px;
  }

  ${({ $layout }) => {
    switch ($layout) {
      case FIXED_HEIGHT_LAYOUT:
        return css`
          &::before {
            height: var(--height);
          }
        `;
      default:
        return css`
          &::before {
            padding-bottom: calc(100% / (var(--aspect-ratio)));
          }
        `;
    }
  }}

  position: relative;
  &::before {
    content: '';
    display: block;
  }
`;

export const Iframe = styled.iframe`
  border: 0;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
`;
