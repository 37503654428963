import styled from 'styled-components';

import { Colors, MediaSmallAndAbove, Spacing } from '@lichtblick/theme';

export const Root = styled.div`
  background-color: ${Colors.Orange};
  box-shadow: 0 0 0 200vmax ${Colors.Orange};
  clip-path: circle(71%);
  font-size: ${Spacing.Xxs};
  line-height: 150%;
  overflow: hidden;
  padding: ${Spacing['3Xs']};
  position: absolute;
  right: 5%;
  text-align: center;
  top: 10%;
  transform: rotate(0.03turn);
  vertical-align: middle;

  ${MediaSmallAndAbove} {
    font-size: ${Spacing.Xs};
  }
`;
