/* eslint-disable monorepo/no-internal-import */
import styled from 'styled-components';

import { Colors, Spacing } from '@lichtblick/theme';
import { StyledHeadline } from '@lichtblick/ui-components';
import { StyledText } from '@lichtblick/ui-components/atoms/Text/Text.styles';

import { StyledMList } from '../../../MList';

export const ProductInformation = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-start;

  & > ${StyledText} {
    color: ${Colors.DarkGray};
    margin-bottom: ${Spacing['3Xs']};
  }
  & > ${StyledHeadline} {
    margin-bottom: ${Spacing.S};
  }
  & > ${StyledMList} {
    margin-bottom: ${Spacing.S};
  }
`;
