import styled from 'styled-components';

import { Colors, MediaDesktop, Spacing } from '@lichtblick/theme';

import { GridRow } from '../../shared';

export const ImageWrapper = styled.div`
  margin-bottom: ${Spacing.M};
  position: relative;
`;

export const GridRowExtend = styled(GridRow)`
  gap: ${Spacing.S};
`;

export const CaseStudyRoot = styled.section<{ $backgroundColor: Colors }>`
  background: ${({ $backgroundColor }) => $backgroundColor};
  padding: ${Spacing.Xl} 0;
  ${MediaDesktop} {
    padding: ${Spacing.Xl} 0;
  }
`;
