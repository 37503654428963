/* eslint-disable monorepo/no-internal-import */
import styled from 'styled-components';

import { BorderRadius, Colors, Spacing } from '@lichtblick/theme';
import { StyledChip } from '@lichtblick/ui-components/atoms/Chip/Chip.styles';

import { ProductInformation } from './shared';

export const ProductCardOptionalRoot = styled.div<{ $backgroundColor: Colors }>`
  align-items: center;
  background: ${({ $backgroundColor }) => $backgroundColor};
  border-radius: ${BorderRadius.S};
  display: flex;
  flex: 1;
  flex-basis: 50%;
  flex-direction: row;
  gap: ${Spacing.Xs};
  justify-content: space-evenly;
  padding: ${Spacing.S};
  position: relative;

  & > .picture-desktop,
  .picture-mobile {
    flex-basis: 50%;
  }

  & > ${StyledChip} {
    left: ${Spacing.Xxs};
    position: absolute;
    top: ${Spacing.Xxs};
  }
  & > ${ProductInformation} {
    flex-basis: 50%;
  }
`;
