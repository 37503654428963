import styled from 'styled-components';

import { Colors, MediaMediumAndAbove, Spacing } from '@lichtblick/theme';

import { AText } from '../AText';
import { GridRow } from '../shared';

export const GridRowExtend = styled(GridRow)`
  margin-bottom: 3rem;
`;

export const ComparisonCards = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;

  ${MediaMediumAndAbove} {
    align-items: center;
    flex-direction: row;
    justify-content: center;
  }
`;

export const CheckboxContainer = styled.div`
  width: 18rem;
  ${MediaMediumAndAbove} {
    width: auto;
  }
`;

export const ComparisonCard = styled.div`
  + * {
    margin-top: 1rem;
  }

  ${MediaMediumAndAbove} {
    margin: 0 0.5rem;
  }
`;

export const ComparisonFooter = styled.div`
  margin: 0 auto;
  max-width: 31rem;
  text-align: center;
`;

export const StyledText = styled(AText)`
  color: ${Colors.Black};
  margin-top: ${Spacing.L};
  opacity: 0.4;
`;
